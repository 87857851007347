<template>
  <div v-if="progress" class="sub-lead mb-0">
    <ol :class="`${isRtl ? 'result-progress-inverted flex-row-reverse justify-content-end' : 'result-progress flex-row justify-content-start'} breadcrumb rounded-0 bg-transparent p-0 mb-0 d-flex`">
      <li v-for="state in possibleStates"
          class="breadcrumb-item text-contrast"
          :key="state"
          :class="{'active fw-bold': stateCurrent(state)}">
        {{ $t(`js.result_progress.states.${state}`) }}
        <IndeterminateSpinner v-if="stateCurrent(state) && progress !== 'finished'"/>
      </li>
    </ol>
  </div>

</template>

<script>

import IndeterminateSpinner from "~/components/backend/live/IndeterminateSpinner.vue";

export default {
  name: "ResultProgress",
  components: {IndeterminateSpinner},
  props: {
    progress: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      possibleStates: ["anonymising", "decrypting", "aggregating", "finished"],
      isRtl: false,
    }
  },
  methods: {
    stateCurrent(state) {
      if(/extracting|mixing/.test(this.progress)) {
          return state === "anonymising";
      }
      return state === this.progress
    }
  },
  watch: {
    '$i18n.locale'() {
      this.isRtl = document.getElementsByTagName('html')[0].dir === 'rtl';
    },
  },
  mounted() {
    this.isRtl = document.getElementsByTagName('html')[0].dir === 'rtl';
  },
}
</script>
