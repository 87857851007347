<template>
  <div class="overflow-auto" v-if="chartType && chartType !== 'none'">
    <hr>
    <horizontal-bars v-if="chartType === 'horizontal_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 24rem"/>
    <vertical-bars v-else-if="chartType === 'vertical_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" :style="{minWidth: `${Math.max(24, ballotOptions.length*5)}rem`}"/>
    <pie-chart v-else-if="chartType === 'pie'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 24rem"/>
  </div>
</template>
<script>
import HorizontalBars from "./charts/HorizontalBars.vue"
import VerticalBars from "./charts/VerticalBars.vue";
import PieChart from "./charts/PieChart.vue";

export default {
  name: 'RankedResultChart',
  components: {PieChart, VerticalBars, HorizontalBars},
  props: {
    optionRanks: {
      type: Object,
      required: true
    },
    rounds: {
      type: Array,
      required: true
    },
    distributionNo: {
      type: Number,
      required: true
    },
    ballotOptions: {
      type: Array,
      required: true
    },
    chartType: {
      type: String,
      default: ['horizontal_bars', 'vertical_bars', 'pie', 'none'][1]
    },
    theme: {
      type: String,
      default: 'light'
    },
    themeColor: {
      type: String,
      default: "#323a52"
    },
    chartOptions: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    truncateLabel(label, maxLength=110, ending='…'){
      return label.length > maxLength ?
          label.slice(0,maxLength)+ending :
          label;
    },
    optionLabel(reference){
      let option = this.ballotOptions.find(option => option.reference === reference)
      if(option) {
        return option.title[this.$i18n.locale] || Object.values(option.title)[0] || 'missing translation'
      } else {
        return reference
      }
    }
  },
  computed:{
    themeColorHSL(){
      const color = Color(this.themeColor);
      if(!color.valid) return null
      return {
        hue: color.hue(),
        saturation: color.saturation(),
        lightness: color.lightness(),
      }
    },
    colors(){
      let countAmount = this.ballotOptions.length
      return this.rounds.map((round, round_index) => [...this.ballotOptions.keys()].map(index => {
        if(this.themeColorHSL){
          return `hsl(${(this.themeColorHSL.hue+(90*round_index)/this.rounds.length)%360}, ${this.themeColorHSL.saturation*(1-index / countAmount)}%, ${this.themeColorHSL.lightness}%)`
        }
        return `hsl(${Math.floor(360 * index / countAmount)}, 65%, 65%)`
      }))
    },
    chartData(){
      let colors = this.colors;
      let references = this.ballotOptions.map(option => option.reference)
      return {
        labels: references.map(reference => this.optionLabel(reference)),
        datasets: this.rounds.map((data, round) => {
          return {
            label: this.$t('js.ranked_summary.round_n', {n: round + 1}),
            backgroundColor: colors[round],
            data: references.map(reference => data.accumulatedCounts[reference] || 0)
          }
        })
      }

    }
  }
}
</script>
