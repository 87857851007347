<script>
import { Bar, mixins } from 'vue-chartjs'

const {reactiveProp} = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: Object,
    options: Object,
    height: null,
    width: null,
    theme: {
      type: String,
      default: 'light'
    }
  },
  computed:{
    colors(){
      switch (this.theme){
        case 'light':
          return {
            fontColor: '#343a40',
            gridColors: '#343a40AE'
          }
        case 'dark':
          return {
            fontColor: '#f8f9fa',
            gridColors: '#f8f9faAE'
          };
        default:
          return {
            fontColor: null,
            gridColors: null
          };
      }
    },
    _appliedOptions(){
      return {
        scales: {
          xAxes:[{
            gridLines: {
              color: this.colors.gridColors,
              zeroLineColor: this.colors.gridColors
            },
            ticks:{
              fontSize: 16,
              fontColor: this.colors.fontColor,
              callback: (label) => {
                let maxLength=21, ending='…'
                return label.length > maxLength ?
                    label.slice(0,maxLength)+ending :
                    label;
              }
            }
          }],
          yAxes:[{
            gridLines: {
              color: this.colors.gridColors,
              zeroLineColor: this.colors.gridColors
            },
            ticks: {
              precision: 0,
              fontSize: 14,
              fontColor: this.colors.fontColor,
              suggestedMin: 0,
              suggestedMax: this.chartData.datasets.reduce((biggest,dataset)=> {
                return Math.max(biggest, ...dataset.data)
              }, 0)
            }
          }]
        },
        legend: {
          display: false
        },
        aspectRatio: 3, //18 / this.chartData.datasets.reduce((top, dataset)=> Math.max(dataset.data.length),1),
        ...this.options
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this._appliedOptions)
  }
}
</script>