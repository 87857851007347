<template>
  <div class="text-center">
    <h5 v-if="label">{{ label }}</h5>
    <div class="progress position-relative">
      <div class="progress-bar" role="progressbar" :style="{ width: `${percent}%` }" :aria-valuenow="count" aria-valuemin="0" :aria-valuemax="total">
        {{count}} / {{total}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      count: Number,
      total: Number,
      label: String
    },
    computed: {
      percent(){
        return this.count * 100.0 / Math.max(this.total, 1)
      }
    }
  }
</script>