<template>
  <div class="text-dark">
    <div :style="listStyle">
      <Option v-for="row in rows"
              :key="row.option.reference"
              :option="row.option"
              :votes="row.count"
              :total="totalCount"
              :elected="elected.includes(row.option.reference)"
              :tied="tied.includes(row.option.reference)"
              :hide-percentage="!contest.resultPercentages"
      />
      <Option
        v-if="blankOption"
        :key="'blank'"
        :option="blankOption.option"
        :votes="blankOption.count"
        :total="totalCount"
        :elected="false"
        :tied="false"
        :hide-percentage="!contest.resultPercentages || contest.disregardBlankVotes"
      />
    </div>
    <NormalResultChart
      v-if="resultData"
      :result-data="resultData"
      :options="contest.options"
      :result-chart="contest.resultChart"
      :theme="theme"
    />
  </div>
</template>
<script>
import Option from "../frontend/shared/Option.vue"
import NormalResultChart from "./NormalResultChart.vue";
import breakpoint from "../../../javascript/entrypoints/shared/breakpoint";
import { flattenOptions } from "../../../javascript/entrypoints/shared/contest_utilities";

export default {
  name: "NormalSummary",
  components: { NormalResultChart, Option },
  props: {
    result: { type: Object, required: true },
    contest: { type: Object, required: true },
    theme: { type: String, default: 'light' },
    locale: { type: String, default: 'en' },
  },
  computed: {
    sortedCounts() {
      if (!this.resultData) return [];
      return this.resultData.optionSortedCounts
    },
    counts() {
      if (!this.resultData) return [];
      return this.resultData.optionCounts
    },
    rows() {
      if (this.sortedCounts) {
        return this.sortedCounts.map(option => {
          return { option: this.flattenOptions(this.contest.options).find(o => o.reference === option[0]), count: option[1] }
        })
      } else {
        return []
      }
    },
    blankOption() {
      if (this.contest.blankOption !== 'disabled') {
        return {
          option: {
            title: { [this.locale]: this.$t('js.standard_option.blank', this.locale) },
            description: { [this.locale]: "" },
            reference: "blank",
            exclusive: true
          }, count: this.counts["blank"]
        }
      } else {
        return null
      }
    },
    elected() {
      return this.resultData.elected
    },
    tied() {
      return this.resultData.tied || []
    },
    totalCount() {
      if (this.contest.disregardBlankVotes) {
        return Object.entries(this.counts)
          .filter(([reference, _count]) => reference.toString() !== "blank")
          .reduce((sum, [_reference, count]) => sum + count, 0);
      } else if (this.contest.consensusRequired && this.contest.countUnvoicedVotesAsBlank) {
        return this.contest.disregardWeight
          ? this.result.voterCounts.activeEligiblesOrVoted
          : this.result.voterCounts.activeEligiblesOrVotedWeight;
      } else {
        return Object.values(this.counts).reduce((sum, count) => sum + count, 0)
      }
    },
    resultData() {
      return this.result.resultData
    },
    columnCount() {
      if (this.rows.length <= 5 || breakpoint.lessEquals('sm')) return 1
      if ((this.rows.length > 5 && this.rows.length <= 10) && breakpoint.greaterEquals('md')) return 2
      let columns = breakpoint.greater('md') ? 3 : 2
      return columns
    },
    listStyle() {
      return `column-count: ${this.columnCount}`
    },
  },
  methods: {
    flattenOptions,
  }
}
</script>

<style>
.result-grid {
  display: grid;
  grid-auto-flow: column;
}
</style>
