<template>
  <div v-if="showConclusion" class="badge" :class="resultStatusClass">
    {{ statusName }}
  </div>
</template>
<script>

export default {
  name: 'ResultConclusion',
  props: {
    result: {
      required: true,
      type: Object
    }
  },
  computed: {
    resultStatusClass() {
      return {
        pending: `text-bg-info`,
        not_passed: `text-bg-danger`,
        passed: `text-bg-success`
      }[this.result.status]
    },
    showConclusion(){
      return ['passed', 'not_passed'].includes(this.result.status)
    },
    statusName(){
      switch (this.result.status) {
        case 'pending': return this.$t('js.result.conclusion.pending');
        case 'passed': return this.$t('js.result.conclusion.passed');
        case 'not_passed': return this.$t('js.result.conclusion.not_passed');
      }
    },
  }
}
</script>
