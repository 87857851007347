<template>
  <div class="option-result" :class="optionBackground">
    <div class="option-content d-flex justify-content-between" v-tooltip="optionTitle">
      <div class="d-flex align-items-center text-truncate">
        <img :src="option.image" class="turnout-image me-3" v-if="option.image">
        <header class="h6 text-truncate mb-0 turnout-title">{{ optionTitle }}</header>
      </div>
      <div class="text-end">
        <div class="turnout-count">{{ truncatedVotes }}</div>
        <div v-if="!hidePercentage" class="text-muted small turnout-percent">{{ optionPercent }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: { type: Object, required: true },
    votes: { type: Number, required: true },
    total: { type: Number, required: true },
    elected: { type: Boolean, default: false },
    tied: { type: Boolean, default: false },
    hidePercentage: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    optionTitle() {
      return this.option.title[this.$i18n.locale] || Object.values(this.option.title)[0] || ""
    },
    optionPercent() {
      return this.total > 0 ? Math.round(this.votes / this.total * 10000) / 100 : 0
    },
    truncatedVotes() {
      return Math.round(this.votes * 100) / 100
    },
    optionBackground() {
      let backgroundColor = 'bg-secondary'
      if (this.elected) {
        backgroundColor = 'bg-success-faded'
      } else if (this.tied) {
        backgroundColor = 'bg-warning-faded'
      }
      return backgroundColor
    }
  }
}
</script>

<style>
.turnout-image {
  max-width: 2em;
  max-height: 2em;
}
</style>
