<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    labelledCounts: Array,
    options: Object,
    height: null,
    width: null
  },
  computed:{
    _appliedOptions(){
      return {
        legend: {
          position: 'right',
          labels: {
            fontSize: 14
          }
        },
        responsiveAnimationDuration: 250,
        aspectRatio: 3,
        responsive: true,
        ...this.options
      }
    },
    data() {
      let colors, countAmount;
      countAmount = this.labelledCounts.length;
      colors = [...Array(countAmount).keys()].map(index => {
        return `hsl(${Math.floor(360 * index / countAmount)}, 100%, 75%)`
      })
      return {
        labels: this.labelledCounts.map(count => {
          return count.label
        }),
        datasets: [{
          backgroundColor: colors,
          data: this.labelledCounts.map(count => {
            return count.count;
          })
        }]
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this._appliedOptions)
  }
}
</script>